import Vue from "vue";
import Vuex from "vuex";

import { mutations } from "@/store/mutations";
import { actions } from "@/store/actions";
import { getters } from "@/store/getters";
import createPersistedState from "vuex-persistedstate";
// import LogRocket from "logrocket";
// import createPlugin from "logrocket-vuex";

// const logrocketPlugin = createPlugin(LogRocket);

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "vuex",
      paths: ["user"],
    }),
  ],
  state: {
    search: "",
    filters: {
      role: "",
      status: "",
      company: "",
      project: "",
      showDeleted: false,
    },
    isSaving: false,
    isLoadingSync: false,
    tabs: [
      {
        table: "users",
        name: "Users",
        isActive: true,
        childComponent: "userList",
        modal: "modalUser",
        permission: "list_users",
        currentPage: 1,
        perPage: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "companies",
        name: "Companies",
        isActive: false,
        childComponent: "companyList",
        modal: "modalCompany",
        permission: "list_companies",
        currentPage: 1,
        perPage: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "projects",
        name: "Projects",
        isActive: false,
        childComponent: "projectList",
        modal: "modalProject",
        permission: "list_projects",
        currentPage: 1,
        perPage: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "cameras",
        name: "Cameras",
        isActive: false,
        childComponent: "cameraList",
        modal: "modalCamera",
        permission: "list_cameras",
        currentPage: 1,
        perPage: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "permissions",
        name: "Permissions",
        isActive: false,
        childComponent: "permissionList",
        modal: "modalPermission",
        permission: "list_permissions",
        currentPage: 1,
        perPage: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "roles",
        name: "Roles",
        isActive: false,
        childComponent: "roleList",
        modal: "modalRole",
        permission: "list_roles",
        currentPage: 1,
        perPage: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "features",
        name: "Features",
        isActive: false,
        childComponent: "featureList",
        modal: "modalFeature",
        permission: "list_features",
        currentPage: 1,
        perPage: 10,
        totalRecords: 0,
        totalPages: 0,
      },
      {
        table: "packages",
        name: "Packages",
        isActive: false,
        childComponent: "packageList",
        modal: "modalSubscriptionPackage",
        permission: "list_packages",
        currentPage: 1,
        perPage: 10,
        totalRecords: 0,
        totalPages: 0,
      },
    ],
    isModalOpen: false,
    modalActiveTabIndex: 0,
    user: {
      id: null,
      fullName: "",
      isAuthenticated: false,
      permissions: [],
    },
    users: [],
    companies: [],
    cameras: [],
    projects: [],
    permissions: [],
    roles: [],
    features: [],
    packages: [],
    selectedCamera: {
      id: null,
      name: "",
      project_id: "",
      aws_bucket: "",
      aws_folder: "",
      aws_region: "",
      timezone: "",
      schedule_group: "",
      company: "",
    },
    selectedUser: {
      id: null,
      fullName: "",
      email: "",
      password: "",
      cameras: [],
      projects: [],
      roles: [],
    },
    selectedCompany: {
      id: null,
      name: "",
      awsBucket: "",
      awsRegion: "",
      awsIotUser: "",
      awsIotUserPolicy: "",
      awsUploadKey: "",
      awsUploadSecret: "",
      roles: [],
      users: [],
    },
    selectedProject: {
      id: null,
      name: "",
      company: null,
    },
    selectedPermission: {
      id: null,
      title: "",
      description: "",
      slug: "",
      allow: "",
      deny: "",
      isActive: false,
      isDeleted: false,
      createdAt: "",
      updatedAt: "",
    },
    selectedRole: {
      id: null,
      title: "",
      description: "",
      isActive: false,
      isDeleted: false,
      createdAt: "",
      updatedAt: "",
      permissions: [],
    },
    selectedPackage: {
      id: null,
      title: "",
      description: "",
      isActive: false,
      isDeleted: false,
      createdAt: "",
      updatedAt: "",
      price: "",
      sharedFeature: [],
    },
    selectedFeature: {
      id: null,
      title: "",
      description: "",
      isActive: false,
      isDeleted: false,
      createdAt: "",
      updatedAt: "",
      sharedPackage: [],
    },
  },
  mutations,
  actions,
  getters,
});

export default store;
