<template>
  <div>
    <div class="field">
      <label class="label">ID</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedCamera.id" disabled />
      </div>
    </div>
    <div class="field">
      <label class="label">Camera Name</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedCamera.name" />
      </div>
    </div>
    <div class="field">
      <label class="label">Company</label>
      <div class="control">
        <div class="select">
          <select v-model="selectedCamera.company_id">
            <option
              v-for="company in companies"
              :value="company.id"
              :key="company.id"
            >
              {{ company.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Project</label>
      <div class="control">
        <div class="select">
          <select v-model="selectedCamera.project_id">
            <option
              v-for="project in filteredProjects"
              :value="project.id"
              :key="project.id"
            >
              {{ project.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Time Zone</label>
      <div class="control">
        <div class="select">
          <select v-model="selectedCamera.timezone">
            <option
              v-for="timezone in Intl.supportedValuesOf('timeZone')"
              :value="timezone"
              :key="timezone"
            >
              {{ timezone }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label">Schedule Group</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="selectedCamera.schedule_group"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      companies: [],
      projects: [],
    };
  },
  computed: {
    ...mapState(["selectedCamera"]),
    filteredProjects() {
      return this.projects.filter(
        (project) => project.company_id === this.selectedCamera.company_id
      );
    },
  },
  mounted() {
    this.$http
      .get("companies/", {
        params: { op: "list_companies", page: 1, perPage: 9999 },
      })
      .then((response) => {
        this.companies = response.data.records;
      });
    this.$http
      .get("projects/", {
        params: { op: "list_projects", page: 1, perPage: 9999 },
      })
      .then((response) => {
        this.projects = response.data.records;
      });
  },
};
</script>

<style></style>
