<template>
  <div class="columns">
    <div class="column">
      <Panel
        header-text="Available Permissions"
        :items="availablePermissions"
        :totalPages="totalPages"
        :totalRecords="totalRecords"
        action-button-text="Add All Permissions"
        panel-theme="link"
        message-text-if-no-items="No Permissions Available"
        @action-button-handler="addAllPermissions"
        @select-item="addPermission"
        @search-items="getAvailablePermissions"
        @get-page="getPage"
        :pagination="true"
      />
    </div>
    <div class="column">
      <Panel
        header-text="Selected Permissions"
        :items="selectedPermissions"
        :totalPages="totalPagesForSharedPermissions"
        action-button-text="Remove All Permissions"
        panel-theme="danger"
        message-text-if-no-items="No permissions given."
        @action-button-handler="removeAllPermissions"
        @search-items="searchSelectedPermissions"
        @select-item="removePermission"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Panel from "@/components/Panel.vue";
export default {
  data: () => ({
    search: "",
    // selectedPermissions: [],
    availablePermissions: [],
    page: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 5,
  }),
  components: {
    Panel,
  },
  methods: {
    getPage(page, search) {
      this.page = page;
      this.getAvailablePermissions(search);
    },
    getAvailablePermissions(search) {
      this.$http
        .get("permissions/", {
          params: {
            op: "list_available_permissions_for_role",
            page: this.page,
            perPage: this.perPage,
            roleId: this.selectedRole.id,
            search: search,
          },
        })
        .then((response) => {
          this.availablePermissions = response.data.records;
          this.totalPages = response.data.totalPages;
          this.totalRecords = response.data.totalRecords;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchSelectedPermissions(search) {
      this.search = search;
    },
    removeAllPermissions() {
      this.selectedPermissions = [];
    },
    addAllPermissions() {
      this.selectedPermissions = [...this.availablePermissions];
    },
    addPermission(perm) {
      let formData = new FormData();
      formData.append("permission", perm.id);
      formData.append("id", this.selectedRole.id);
      formData.append("op", "attach_permission");

      this.$http
        .post("roles/", formData)
        .then((response) => {
          console.log(response);
          if (this.selectedRole.sharedPermission) {
            this.selectedRole.sharedPermission.push(perm);
          } else {
            this.$set(this.selectedRole, "sharedPermission", [perm]);
          }
          this.getAvailablePermissions();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removePermission(perm) {
      let formData = new FormData();
      formData.append("permission", perm.id);
      formData.append("id", this.selectedRole.id);
      formData.append("op", "detach_permission");

      this.$http
        .post("roles/", formData)
        .then((response) => {
          console.log(response);
          this.selectedRole.sharedPermission?.splice(
            this.selectedRole.sharedPermission?.findIndex(
              (p) => p.id === perm.id
            ),
            1
          );
          this.getAvailablePermissions();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState(["selectedRole"]),
    selectedPermissions() {
      return this.selectedRole.sharedPermission?.filter((perm) =>
        perm.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    totalPagesForSharedPermissions() {
      return Math.ceil(
        this.selectedRole.sharedPermission?.length / this.perPage
      );
    },
  },
  mounted() {
    this.getAvailablePermissions();
  },
};
</script>

<style>
select {
  display: block;
  width: 100%;
}
.panel-body {
  overflow-y: scroll;
  max-height: 300px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.panel-body::-webkit-scrollbar {
  display: none;
}
</style>
