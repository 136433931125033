<template>
  <div class="container mt-5">
    <nav class="pagination" role="navigation" aria-label="pagination">
      <a
        class="pagination-previous"
        @click="movePage(-1)"
        :disabled="currentPage === 1"
        >Previous</a
      >
      <a
        class="pagination-next"
        @click="movePage(1)"
        :disabled="currentPage === totalPages"
        >Next page</a
      >
      <ul class="pagination-list">
        <li v-for="page in totalPages" :key="page">
          <a
            :class="[
              'pagination-link',
              page == currentPage ? 'is-current' : '',
            ]"
            aria-label="Goto page 1"
            @click="gotoPage(page)"
            >{{ page }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  props: {
    size: {
      default: 10,
    },
    start: {
      default: 1,
    },
  },
  computed: {
    ...mapGetters(["activeTab", "activeTabIndex"]),
    currentPage: function () {
      return this.activeTab.currentPage;
    },
    totalPages: function () {
      return this.activeTab.totalPages;
    },
  },
  methods: {
    ...mapMutations(["SET_CURRENT_PAGE", "SET_PAGE_SIZE"]),
    ...mapActions(["reset", "fetchList"]),
    movePage(direction) {
      let page = this.currentPage;
      if (direction === -1 && page > 1) {
        page--;
      } else if (direction === 1 && page < this.totalPages) {
        page++;
      } else {
        return;
      }

      this.SET_CURRENT_PAGE({
        tabIndex: this.activeTabIndex,
        page,
      });

      this.fetchList();
    },
    gotoPage(page) {
      this.SET_CURRENT_PAGE({
        tabIndex: this.activeTabIndex,
        page: page,
      });

      this.fetchList();
    },
  },
};
</script>

<style></style>
