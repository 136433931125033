<template>
  <div>
    <div class="field">
      <label class="label">Version</label>
      <div class="control">
        <div class="select">
          <select
            v-model="selectedCamera.version"
            @change="resetStorageSettings"
          >
            <option value="1">Version 1</option>
            <option value="2">Version 2</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="selectedCamera.version == 1">
      <div class="field">
        <label class="label">Bucket</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="selectedCamera.aws_bucket"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Folder</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="selectedCamera.aws_folder"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Region</label>
        <div class="control">
          <input
            class="input"
            type="text"
            v-model="selectedCamera.aws_region"
          />
        </div>
      </div>
    </div>
    <div v-else-if="selectedCamera.version == 2">
      <div class="field">
        <label class="label">IP Address</label>
        <div class="control">
          <input class="input" type="text" v-model="selectedCamera.ip" />
        </div>
      </div>
      <div class="field">
        <label class="label">Port</label>
        <div class="control">
          <input class="input" type="text" v-model="selectedCamera.port" />
        </div>
      </div>
      <div class="field">
        <label class="label">Channel</label>
        <div class="control">
          <input class="input" type="number" v-model="selectedCamera.channel" />
        </div>
      </div>
      <div class="field">
        <label class="label">Username</label>
        <div class="control">
          <input class="input" type="text" v-model="selectedCamera.username" />
        </div>
      </div>
      <div class="field">
        <label class="label">Password</label>
        <div class="control">
          <input
            class="input"
            type="password"
            v-model="selectedCamera.password"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["selectedCamera"]),
  },
  methods: {
    resetStorageSettings() {
      if (
        confirm(
          "Are you sure you want to change the camera version? This will reset all storage settings."
        )
      ) {
        this.selectedCamera.aws_bucket = "";
        this.selectedCamera.aws_folder = "";
        this.selectedCamera.aws_region = "";
        this.selectedCamera.ip = "";
        this.selectedCamera.port = "";
        this.selectedCamera.channel = "";
        this.selectedCamera.username = "";
        this.selectedCamera.password = "";
      } else {
        this.selectedCamera.version = this.selectedCamera.version == 1 ? 2 : 1;
      }
    },
  },
};
</script>

<style></style>
