<template>
  <div>
    <label class="label">Status</label>
    <div class="field">
      <button
        :class="[
          'button',
          'is-info',
          isLoading.checkDeployment ? 'is-loading' : '',
        ]"
        @click="checkDeployment"
      >
        Check Deployment
      </button>
    </div>
    <label class="label"> Bucket </label>
    <div class="field has-addons">
      <div v-if="newBucket" class="control">
        <input
          class="input"
          type="text"
          v-model="newBucketName"
          placeholder="Enter bucket name"
        />
      </div>
      <div v-if="!newBucket" class="control">
        <div :class="['select', isLoading.bucketList ? 'is-loading' : '']">
          <select
            v-model="selectedCompany.awsBucket"
            :disabled="selectedCompany.id"
          >
            <option value="">-- Select a bucket --</option>
            <option
              v-for="(bucket, i) in buckets"
              :value="bucket.Name"
              :key="i"
            >
              {{ bucket.Name }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="!newBucket" class="control">
        <button
          class="button is-info"
          @click="newBucket = true"
          :disabled="isLoading.bucketList"
        >
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
        </button>
      </div>
      <div v-if="newBucket" class="control">
        <button
          :class="[
            'button',
            'is-success',
            isLoading.bucketCreate ? 'is-loading' : '',
          ]"
          @click="create"
        >
          <span class="icon">
            <i class="fas fa-save"></i>
          </span>
        </button>
      </div>
      <div v-if="newBucket" class="control">
        <button class="button is-danger" @click="newBucket = false">
          <span class="icon">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </div>
    </div>
    <label class="label">Region</label>
    <div class="field has-addons">
      <div class="control">
        <input
          type="text"
          class="input"
          v-model="selectedCompany.awsRegion"
          placeholder="Click button to get the region"
          readonly
        />
      </div>
      <button
        :class="['button', 'is-info', isLoading.region ? 'is-loading' : '']"
        @click="getBucketLocation(selectedCompany.awsBucket)"
      >
        <span class="icon">
          <i class="fas fa-globe"></i>
        </span>
      </button>
    </div>
    <label class="label">IoT Device User</label>
    <div class="field has-addons">
      <div class="control">
        <input type="text" class="input" v-model="selectedCompany.awsIotUser" />
      </div>
      <button
        :class="['button', 'is-info', isLoading.user ? 'is-loading' : '']"
        @click="getOrCreateIoTDeviceUser"
      >
        <span class="icon">
          <i class="fas fa-user-plus"></i>
        </span>
      </button>
    </div>
    <label class="label">IoT User Policy</label>
    <div class="field has-addons">
      <div class="control">
        <input
          type="text"
          class="input"
          v-model="selectedCompany.awsIotUserPolicy"
        />
      </div>
      <button
        :class="['button', 'is-info', isLoading.policy ? 'is-loading' : '']"
        @click="getOrCreateIoTUserPolicy"
      >
        <span class="icon">
          <i class="fas fa-file-contract"></i>
        </span>
      </button>
    </div>
    <label class="label">Upload Key</label>
    <div class="field has-addons">
      <div class="control">
        <input
          id="awsUploadKey"
          class="input"
          type="text"
          v-model="selectedCompany.awsUploadKey"
          readonly
        />
      </div>
      <div class="control">
        <button
          v-if="!selectedCompany.awsUploadKey"
          :class="[
            'button',
            'is-warning',
            isLoading.secret ? 'is-loading' : '',
          ]"
          @click="createSecrets"
        >
          <span class="icon">
            <i class="fas fa-key"></i>
          </span>
          <span>Generate</span>
        </button>
        <button
          v-if="selectedCompany.awsUploadKey"
          class="button is-success"
          @click="copyKey"
        >
          <span class="icon">
            <i class="fas fa-copy"></i>
          </span>
        </button>
      </div>
    </div>
    <label class="label">Upload Secret</label>
    <div class="field has-addons">
      <div class="control is-expanded">
        <input
          id="awsUploadSecret"
          class="input"
          type="text"
          v-model="selectedCompany.awsUploadSecret"
          readonly
        />
      </div>
      <div class="control" v-if="selectedCompany.awsUploadKey">
        <button class="button is-success" @click="copySecret">
          <span class="icon">
            <i class="fas fa-copy"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      newBucket: false,
      newBucketName: "",
      buckets: [],
      iotDeviceUser: "",
      iotUserPolicy: "",
      isLoading: {
        checkDeployment: false,
        bucketList: false,
        bucketCreate: false,
        region: false,
        user: false,
        policy: false,
        secret: false,
      },
    };
  },
  computed: {
    ...mapState(["selectedCompany"]),
  },
  methods: {
    listBuckets() {
      this.isLoading.bucketList = true;
      this.$http
        .get("buckets/", { params: { op: "list_buckets" } })
        .then((response) => {
          console.log(response.data);
          this.buckets = response.data;
          this.isLoading.bucketList = false;
        });
    },
    getBucketLocation(bucket) {
      this.isLoading.region = true;
      this.$http
        .get("buckets/", {
          params: {
            op: "get_bucket_location",
            name: bucket,
          },
        })
        .then((response) => {
          this.selectedCompany.awsRegion = response.data;
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading.region = false;
        });
    },
    createUser() {
      if (this.selectedCompany.awsIotUser === "") {
        confirm(
          `No user name specified. Shall I continue with ${this.selectedCompany.awsBucket}-iot-user?`
        )
          ? (this.selectedCompany.awsIotUser = `${this.selectedCompany.awsBucket}-iot-user`)
          : null;
      }
      let formData = new FormData();
      formData.append("user", this.selectedCompany.awsIotUser);
      formData.append("op", "create_iot_user");
      formData.append("cid", this.selectedCompany.id);

      this.$http
        .post("buckets/", formData)
        .then((response) => {
          console.log(response.data);
          this.$toast.open({
            position: "top",
            type: "success",
            message: "User created!",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast.open({
            position: "top",
            type: "error",
            message: "Couldn't create user.",
          });
        });
    },
    getOrCreateIoTDeviceUser() {
      this.isLoading.user = true;
      this.$http
        .get("buckets/", {
          params: {
            op: "get_iot_user",
            id: this.selectedCompany.id,
            user: this.selectedCompany.awsIotUser,
          },
        })
        .then((response) => {
          console.log(response);
          this.selectedCompany.awsIotUser = response.data.user;
        })
        .catch((error) => {
          let res = error.response.data;
          switch (res.error) {
            case "NoSuchUser":
              if (
                confirm(
                  "User " +
                    res.user +
                    " doesn't exist. Do you want to create it?"
                )
              ) {
                this.selectedCompany.awsIotUser = res.user;
                this.createUser();
              }

              break;
            case "NoSuchUserDB":
              this.$toast.open({
                position: "top",
                type: "warning",
                message:
                  "User found in backend but not in database. Please update record to save this user in database.",
              });
              this.selectedCompany.awsIotUser = res.user;
              break;
            case "NoUpdatedUser":
              this.$toast.open({
                position: "top",
                type: "warning",
                message:
                  "User found in database is different from the username provided. Ask system admin to remove existing user before creating a new one.",
              });
              break;
            case "NoSuchUserBA":
              confirm("User found in database but not in backend. Create?")
                ? this.createUser()
                : null;
              break;
            case "NoCompanyBucket":
              this.$toast.open({
                position: "top",
                type: "info",
                message:
                  "No company bucket found. Please create a new bucket first.",
              });
              break;
            default:
              break;
          }
        })
        .finally(() => {
          this.isLoading.user = false;
        });
    },
    getOrCreateIoTUserPolicy() {
      this.$http
        .get("buckets/", {
          params: { op: "get_user_policy", id: this.selectedCompany.id },
        })
        .then((response) => {
          this.selectedCompany.awsIotUserPolicy = response.data.policy;
        })
        .catch((error) => {
          let err = error.response.data;
          if (err === "PolicyNotAttached") {
            confirm(
              "Policy not attached to the user. Do you want to attach it?"
            )
              ? this.attachPolicy()
              : null;
          } else {
            confirm(
              error.response.data.policy + " not found. Create and attach?"
            )
              ? this.createUserPolicy()
              : null;
          }
        });
    },
    createUserPolicy() {
      this.isLoading.policy = true;
      let formData = new FormData();
      formData.append("op", "create_and_attach_user_policy");
      formData.append("id", this.selectedCompany.id);

      this.$http
        .post(`buckets/`, formData)
        .then((response) => {
          this.selectedCompany.awsIotUserPolicy = response.data.policy;
        })
        .catch((err) => {
          console.log(err);
          this.$toast.open({
            position: "top",
            type: "error",
            message: "Couldn't create policy.",
          });
        })
        .finally(() => {
          this.isLoading.policy = false;
        });
    },
    attachPolicy() {
      this.isLoading.policy = true;
      let formData = new FormData();
      formData.append("op", "attach_policy");
      formData.append("id", this.selectedCompany.id);

      this.$http
        .post(`buckets/`, formData)
        .then((response) => {
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.open({
            position: "top",
            type: "error",
            message: "Couldn't attach policy.",
          });
        })
        .finally(() => {
          this.isLoading.policy = false;
        });
    },
    create() {
      this.isLoading.bucketCreate = true;
      let formData = new FormData();
      formData.append("name", this.newBucketName);
      formData.append("cid", this.selectedCompany.id);
      formData.append("op", "create_bucket");
      this.$http
        .post("buckets/", formData)
        .then((res) => {
          var data = res.data;
          this.newBucket = false;
          this.selectedCompany.awsBucket = this.newBucketName;
          this.selectedCompany.awsRegion = data.location;
          this.$toast.open({
            position: "top",
            type: "success",
            message: `Bucket ${this.newBucketName} created in ${data.location} region.`,
          });
          this.newBucketName = "";
          this.listBuckets();
        })
        .catch((error) => {
          this.$toast.open({
            position: "top",
            type: "error",
            message: "Failed! " + error.response.data + ".",
          });
        })
        .finally(() => {
          this.isLoading.bucketCreate = false;
        });
    },
    createSecrets() {
      this.isLoading.secret = true;
      let formData = new FormData();
      formData.append("op", "create_secrets");
      formData.append("id", this.selectedCompany.id);
      this.$http
        .post("buckets/", formData)
        .then((response) => {
          this.selectedCompany.awsUploadKey = response.data.accessKey;
          this.selectedCompany.awsUploadSecret = response.data.secretKey;
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            position: "top",
            type: "warning",
            message: "Anahtarlar oluşturulamadı. Lütfen tekrar deneyin.",
          });
        })
        .finally(() => {
          this.isLoading.secret = false;
        });
    },
    checkDeployment() {
      this.isLoading.checkDeployment = true;
      this.$http
        .get("buckets/", {
          params: { op: "deployment_status", id: this.selectedCompany.id },
        })
        .then((response) => {
          console.log(response);
          this.$toast.open({
            position: "top",
            type: "success",
            message: "Everything looks good!",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            position: "top",
            type: "warning",
            message: "Deployment isn't complete. Please check logs.",
          });
        })
        .finally(() => {
          this.isLoading.checkDeployment = false;
        });
    },
    copyKey() {
      let copyText = document.getElementById("awsUploadKey");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      this.$toast.open({
        position: "top",
        type: "info",
        message: "Upload key copied to clipboard.",
      });
    },
    copySecret() {
      let copyText = document.getElementById("awsUploadSecret");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      this.$toast.open({
        position: "top",
        type: "info",
        message: "Upload secret copied to clipboard.",
      });
    },
  },
  mounted() {
    this.listBuckets();
  },
};
</script>

<style></style>
