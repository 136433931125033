import { SET_SELECTED_USER } from "@/store/mutations.js";
export const users = {
  getFormData: (state) => {
    let formData = new FormData();
    formData.append("fullName", state.selectedUser.fullName);
    formData.append("email", state.selectedUser.email);
    formData.append("id", state.selectedUser.id);
    formData.append("isActive", state.selectedUser.isActive);
    formData.append("isDeleted", state.selectedUser.isDeleted);
    formData.append("emailValidation", state.selectedUser.emailValidation);
    formData.append(
      "op",
      state.selectedUser.id ? "update_user" : "create_user"
    );
    return formData;
  },
  reset: (commit) => {
    commit(SET_SELECTED_USER, {
      id: null,
      fullName: "",
      email: "",
      password: "",
      isActive: false,
      isDeleted: false,
      emailValidation: 0,
      roles: [],
      cameras: [],
      projects: [],
      users: [],
    });
  },
  saveCallBack: (response, state, dispatch) => {
    state.selectedUser.id = response.data.id;
    dispatch("fetchList");
  },
  validate: (state) => {
    return (
      state.selectedUser.fullName?.length > 2 &&
      state.selectedUser.email &&
      state.selectedPermission.isActive !== "" &&
      state.selectedPermission.isDeleted !== ""
    );
  },
  getFetchParams: (state) => {
    let params = {};

    if (state.filters.role) {
      params.role = state.filters.role;
    }
    if (state.filters.company) {
      params.company = state.filters.company;
    }
    if (state.filters.project) {
      params.project = state.filters.project;
    }
    if (state.filters.status) {
      params.status = state.filters.status;
    }

    return params;
  },
};
