<template>
  <modal>
    <div class="tabs is-boxed">
      <ul>
        <li
          v-for="(tab, i) in tabs"
          :class="[modalActiveTabIndex === i ? 'is-active' : '']"
          :key="i"
        >
          <a @click="SET_MODAL_ACTIVE_TAB_INDEX(i)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <component :is="tabs[modalActiveTabIndex].component" />
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import General from "./tabs/General.vue";
import Companies from "./tabs/Companies.vue";
import Projects from "./tabs/Projects.vue";
import Cameras from "./tabs/Cameras.vue";
import Operations from "./tabs/Operations.vue";
import Roles from "./tabs/Roles.vue";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    Modal,
    General,
    Companies,
    Projects,
    Cameras,
    Operations,
    Roles,
  },
  data() {
    return {
      tabs: [
        {
          name: "General",
          component: General,
          isActive: true,
        },
        {
          name: "Roles",
          component: Roles,
          isActive: false,
        },
        {
          name: "Companies",
          component: Companies,
          isActive: false,
        },
        {
          name: "Projects",
          component: Projects,
          isActive: false,
        },
        {
          name: "Cameras",
          component: Cameras,
          isActive: false,
        },
        {
          name: "Operations",
          component: Operations,
          isActive: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["modalActiveTabIndex"]),
  },
  methods: {
    ...mapMutations(["SET_MODAL_ACTIVE_TAB_INDEX"]),
  },
};
</script>

<style scoped>
.level-item {
  flex-shrink: 1;
  padding-right: 1em;
}
</style>
