<template>
  <modal>
    <div class="tabs is-boxed">
      <ul>
        <li
          v-for="(tab, i) in tabs"
          :class="[tab.isActive ? 'is-active' : '']"
          :key="i"
        >
          <a @click="openTab(i)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <component :is="activeTab.component" />
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import General from "./tabs/General.vue";
import Info from "./tabs/Info.vue";
// import Permissions from "./tabs/Permissions.vue";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      tabs: [
        {
          name: "General",
          component: General,
          isActive: true,
        },
        {
          name: "Info",
          component: Info,
          isActive: false,
        },
      ],
    };
  },
  computed: {
    activeTab() {
      return this.tabs.find((tab) => tab.isActive);
    },
  },
  methods: {
    openTab(index) {
      this.tabs.forEach((tab, i) => {
        tab.isActive = i === index;
      });
    },
  },
};
</script>

<style></style>
