<template>
  <div>
    <div class="level notification" v-if="selectedUser.isActive == '0'">
      <div class="level-item">
        This user is DEACTIVATED and can not log in. Click on "Activate User" to
        activate the user.
      </div>
      <div class="level-item">
        <button :class="['button', 'is-success']" @click="activateUser">
          Activate User
        </button>
      </div>
    </div>
    <div class="level notification" v-else>
      <div class="level-item">
        This user is ACTIVE. Click on "Deactivate User" to deactivate the user.
      </div>
      <div class="level-item">
        <button :class="['button', 'is-warning']" @click="deactivateUser">
          Deactivate User
        </button>
      </div>
    </div>
    <div class="level notification">
      <div class="level-item">
        Click on "Reset Password" to generate a new password. The password will
        be sent to the user's email.
      </div>
      <div class="level-item">
        <button :class="['button', 'is-info']" @click="resetPassword">
          Reset Password
        </button>
      </div>
    </div>
    <div class="level notification">
      <div class="level-item">
        If you delete the user, it will be removed from all companies, projects
        and cameras. The user will be marked as deleted and will not be able to
        log in.
      </div>
      <div class="level-item">
        <button :class="['button', 'is-danger']" @click="deleteUser">
          Delete User
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  methods: {
    deleteUser() {
      if (!confirm("Are you sure you want to delete this user?")) return;
      var formData = new FormData();
      formData.append("id", this.selectedUser.id);
      formData.append("op", "delete_user");
      this.$http.post("users/", formData).then((response) => {
        if (response.status !== 200) {
          this.$toast.open({
            position: "top",
            type: "error",
            message: "Failed to delete user. Please contact the administrator.",
          });
          return;
        }
        this.$toast.open({
          position: "top",
          type: "warning",
          message: "User has been deleted.",
        });
        this.selectedUser.isActive = "0";
        this.selectedUser.isDeleted = "1";
        this.selectedUser.cameras = [];
        this.selectedUser.projects = [];
        this.selectedUser.companies = [];
        this.selectedUser.roles = [];
      });
    },
    activateUser() {
      var formData = new FormData();
      formData.append("id", this.selectedUser.id);
      formData.append("op", "activate_user");
      this.$http.post("users/", formData).then((response) => {
        if (response.status !== 200) {
          this.$toast.open({
            position: "top",
            type: "error",
            message:
              "Failed to activate user. Please contact the administrator.",
          });
          return;
        }
        this.$toast.open({
          position: "top",
          type: "success",
          message: "User has been activated.",
        });
        this.selectedUser.isActive = "1";
      });
    },
    deactivateUser() {
      var formData = new FormData();
      formData.append("id", this.selectedUser.id);
      formData.append("op", "deactivate_user");
      this.$http.post("users/", formData).then((response) => {
        if (response.status !== 200) {
          this.$toast.open({
            position: "top",
            type: "error",
            message:
              "Failed to deactivate user. Please contact the administrator.",
          });
          return;
        }
        this.$toast.open({
          position: "top",
          type: "warning",
          message: "User has been deactivated.",
        });
        this.selectedUser.isActive = "0";
      });
    },
    resetPassword() {
      if (
        !confirm(
          "A new password will be sent to users' e-mail address.\n\nAre you sure?"
        )
      )
        return;

      var formData = new FormData();
      formData.append("id", this.selectedUser.id);
      formData.append("op", "reset_password");

      this.$http
        .post("users/", formData)
        .then((response) => {
          if (response.status !== 200) {
            this.$toast.open({
              position: "top",
              type: "error",
              message:
                "Failed to update password. Please contact the administrator.",
            });

            return;
          }
          this.$toast.open({
            position: "top",
            type: "success",
            message: "New password has been sent to users' e-mail address.",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState(["selectedUser"]),
  },
};
</script>

<style scoped>
.level-item {
  flex-shrink: 1;
  padding-right: 1em;
}
</style>
