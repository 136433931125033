<template>
  <div>
    <div class="field">
      <label class="label">Created At</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="selectedCamera.created_at"
          readonly
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Updated At</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="selectedCamera.updated_at"
          readonly
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Next Photo</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="selectedCamera.next_photo_date"
          readonly
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Last Snapshot Status</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="selectedCamera.last_snapshot_status"
          readonly
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Serial Number</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="selectedCamera.serial_number"
          readonly
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["selectedCamera"]),
  },
};
</script>

<style></style>
