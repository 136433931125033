<template>
  <div class="columns">
    <div class="column">
      <Panel
        header-text="Available Cameras"
        :items="availableCameras"
        :totalPages="totalPages"
        :totalRecords="totalRecords"
        action-button-text="Add All Cameras"
        panel-theme="link"
        message-text-if-no-items="No Cameras Available"
        @action-button-handler="addAllCameras"
        @select-item="addCamera"
        @search-items="getAvailableCameras"
        @get-page="getPage"
        displayField="longName"
        :pagination="true"
      />
    </div>
    <div class="column">
      <Panel
        header-text="Selected Cameras"
        :items="selectedCameras"
        :totalPages="totalPagesForSharedCameras"
        action-button-text="Remove All Cameras"
        panel-theme="danger"
        message-text-if-no-items="No cameras selected."
        @action-button-handler="removeAllCameras"
        @search-items="searchSelectedCameras"
        displayField="longName"
        @select-item="removeCamera"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Panel from "@/components/Panel.vue";
export default {
  data: () => ({
    search: "",
    // selectedCameras: [],
    availableCameras: [],
    page: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 5,
  }),
  components: {
    Panel,
  },
  methods: {
    getPage(page, search) {
      this.page = page;
      this.getAvailableCameras(search);
    },
    getAvailableCameras(search) {
      this.$http
        .get("cameras/", {
          params: {
            op: "list_available_cameras_for_user",
            search: search,
            uid: this.selectedUser.id,
            page: this.page,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.availableCameras = response.data.records;
          this.totalPages = response.data.totalPages;
          this.totalRecords = response.data.totalRecords;
        });
    },
    searchSelectedCameras(search) {
      this.search = search;
    },
    addAllCameras() {
      this.selectedCameras = [...this.availableCameras];
    },
    removeAllCameras() {
      this.selectedCameras = [];
    },
    addCamera(camera) {
      let formData = new FormData();
      formData.append("camera", camera.id);
      formData.append("id", this.selectedUser.id);
      formData.append("op", "attach_camera");

      this.$http
        .post("users/", formData)
        .then((response) => {
          console.log(response);
          if (this.selectedUser.cameras) {
            this.selectedUser.cameras.push(camera);
          } else {
            this.$set(this.selectedUser, "cameras", [camera]);
          }
          this.getAvailableCameras();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeCamera(camera) {
      let formData = new FormData();
      formData.append("camera", camera.id);
      formData.append("id", this.selectedUser.id);
      formData.append("op", "detach_camera");

      this.$http
        .post("users/", formData)
        .then((response) => {
          console.log(response);
          this.selectedUser.cameras?.splice(
            this.selectedUser.cameras.findIndex((r) => r.id === camera.id),
            1
          );
          this.getAvailableCameras();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState(["selectedUser"]),
    selectedCameras() {
      console.log(this.selectedUser);
      return this.selectedUser.cameras.filter((camera) => {
        return camera.longName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    totalPagesForSharedCameras() {
      return Math.ceil(this.selectedUser.cameras.length / this.perPage);
    },
  },
  mounted() {
    this.getAvailableCameras();
  },
};
</script>

<style></style>
