export const getters = {
  activeTab: (state) => {
    for (let tab in state.tabs) {
      if (state.tabs[tab].isActive) {
        return state.tabs[tab];
      }
    }
  },
  activeTabIndex: (state) => {
    for (let tab in state.tabs) {
      if (state.tabs[tab].isActive) {
        return tab;
      }
    }
  },
  availableTabs: (state) => {
    let tabs = [];
    for (let tab in state.tabs) {
      if (state.user.permissions.includes(state.tabs[tab].permission)) {
        tabs.push(state.tabs[tab]);
      }
    }
    return tabs;
  },
};
