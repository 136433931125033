import { SET_SELECTED_FEATURE } from "@/store/mutations.js";
export const features = {
  getFormData: (state) => {
    let formData = new FormData();
    formData.append("title", state.selectedFeature.title);
    formData.append("description", state.selectedFeature.description);
    formData.append("value", state.selectedFeature.value);
    formData.append("isActive", state.selectedFeature.isActive);
    formData.append("id", state.selectedFeature.id);
    formData.append("isDeleted", state.selectedFeature.isDeleted);
    return formData;
  },
  reset: (commit) => {
    commit(SET_SELECTED_FEATURE, {
      id: null,
      title: "",
      description: "",
      isActive: false,
      isDeleted: false,
      packages: [],
    });
  },
  saveCallBack: (response, state, dispatch) => {
    state.selectedFeature.id = response.data.id;
    dispatch("fetchList");
  },
  validate: (state) => {
    return (
      state.selectedFeature.title?.length > 2 &&
      state.selectedFeature.isActive !== "" &&
      state.selectedFeature.isDeleted !== ""
    );
  },
  getFetchParams: (state) => {
    let params = {};

    if (state.filters.showDeleted) {
      params.company = state.filters.showDeleted;
    }
    return params;
  },
};
