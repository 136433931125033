<template>
  <div class="roles-table">
    <button-group />
    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Description</th>
          <th>Active</th>
          <th v-if="filters.showDeleted">Deleted</th>
          <th>Created At</th>
          <th>Updated At</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="roles.length">
        <tr v-for="(role, i) in roles" :key="i" @click="edit(role)">
          <td>{{ role.id }}</td>
          <td>{{ role.title }}</td>
          <td>{{ role.description }}</td>
          <td>{{ role.isActive }}</td>
          <td v-if="filters.showDeleted">{{ role.isDeleted }}</td>
          <td>{{ role.createdAt }}</td>
          <td>{{ role.updatedAt }}</td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoadingSync">
        <tr>
          <td colspan="8">Loading roles...</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="8">No roles found</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ButtonGroup from "@/components/ButtonGroup.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    "button-group": ButtonGroup,
  },
  computed: {
    ...mapState(["roles", "isLoadingSync", "filters"]),
  },
  methods: {
    ...mapActions(["fetchList"]),
    ...mapMutations(["SET_SELECTED_ROLE", "SET_MODAL_STATE"]),
    edit(role) {
      this.SET_SELECTED_ROLE(role);
      this.SET_MODAL_STATE(true);
    },
  },

  mounted() {
    this.fetchList();
  },
};
</script>

<style></style>
