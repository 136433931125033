<template>
  <div class="content">
    <p>This role is being used by following users:</p>
    <ul>
      <li v-for="user in selectedRole.sharedUser" :key="user.id">
        {{ user.fullName }}
      </li>
    </ul>
    <p>This role is being used by following companies:</p>
    <ul>
      <li v-for="company in selectedRole.sharedCompany" :key="company.id">
        {{ company.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["selectedRole"]),
  },
};
</script>

<style></style>
