import { SET_SELECTED_CAMERA } from "@/store/mutations.js";
export const cameras = {
  getFormData: (state) => {
    let formData = new FormData();
    formData.append("id", state.selectedCamera.id);
    formData.append("name", state.selectedCamera.name);
    formData.append("project_id", state.selectedCamera.project_id);
    formData.append("timezone", state.selectedCamera.timezone);
    formData.append("schedule_group", state.selectedCamera.schedule_group);
    formData.append("version", state.selectedCamera.version);

    if (state.selectedCamera.version == 1) {
      formData.append("aws_bucket", state.selectedCamera.aws_bucket);
      formData.append("aws_folder", state.selectedCamera.aws_folder);
      formData.append("aws_region", state.selectedCamera.aws_region);
    } else if (state.selectedCamera.version == 2) {
      formData.append("ip", state.selectedCamera.ip);
      formData.append("port", state.selectedCamera.port);
      formData.append("username", state.selectedCamera.username);
      formData.append("password", state.selectedCamera.password);
      formData.append("channel", state.selectedCamera.channel);
    }
    formData.append(
      "op",
      state.selectedCamera.id ? "update_camera" : "create_camera"
    );
    return formData;
  },
  saveCallBack: (response, state, dispatch) => {
    state.selectedCamera.id = response.data.id;
    dispatch("fetchList");
  },
  validate: (state) => {
    if (state.selectedCamera.version == 1) {
      return (
        state.selectedCamera.name?.length > 2 &&
        state.selectedCamera.project_id &&
        state.selectedCamera.aws_bucket &&
        state.selectedCamera.aws_folder &&
        state.selectedCamera.aws_region &&
        state.selectedCamera.timezone
      );
    } else {
      return (
        state.selectedCamera.name?.length > 2 &&
        state.selectedCamera.project_id &&
        state.selectedCamera.ip &&
        state.selectedCamera.port &&
        state.selectedCamera.username &&
        state.selectedCamera.password &&
        state.selectedCamera.channel &&
        state.selectedCamera.timezone
      );
    }
  },
  reset: (commit) => {
    commit(SET_SELECTED_CAMERA, {
      id: null,
      name: "",
      project_id: "",
      company: "",
      aws_bucket: "",
      aws_folder: "",
      region: "",
      timezone: "",
      schedule_group: "",
    });
  },
  getFetchParams(state) {
    let params = {};

    if (state.filters.company) {
      params.company = state.filters.company;
    }
    if (state.filters.project) {
      params.project = state.filters.project;
    }

    return params;
  },
};
