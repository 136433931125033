<template>
  <div class="section">
    <h1 class="title">{{ user.fullName }}</h1>
    <h1 class="subtitle">
      Welcome to the admin panel
      <button class="button logout is-text" @click="logout">
        <span class="icon is-small">
          <i class="fas fa-sign-out-alt"></i>
        </span>
        <span>Logout</span>
      </button>
    </h1>

    <div class="container">
      <div class="tabs is-boxed is-centered">
        <ul>
          <li
            v-for="(tab, i) in tabs"
            :key="i"
            :class="[tab.isActive ? 'is-active' : '']"
          >
            <a @click="SET_ACTIVE_TAB(tab.table)" v-html="tab.name"></a>
          </li>
        </ul>
      </div>
      <component :is="activeTab.childComponent" />
    </div>
    <Pagination size="10" start="1" />
    <component :is="activeTab.modal" />
  </div>
</template>

<script>
import userList from "../components/UserList.vue";
import companyList from "../components/CompanyList.vue";
import projectList from "../components/ProjectList.vue";
import cameraList from "../components/CameraList.vue";
import permissionList from "../components/PermissionList.vue";
import roleList from "../components/RoleList.vue";
import packageList from "../components/PackageList.vue";
import featureList from "../components/FeatureList.vue";
import modalCamera from "../components/modals/camera/";
import modalUser from "../components/modals/user";
import modalProject from "../components/modals/project";
import modalPermission from "../components/modals/permission";
import modalRole from "../components/modals/role";
import modalCompany from "../components/modals/company/";
import modalSubscriptionPackage from "../components/modals/subscriptionPackage/";
import modalFeature from "../components/modals/feature";
import Pagination from "../components/Pagination.vue";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    userList,
    companyList,
    projectList,
    cameraList,
    permissionList,
    roleList,
    packageList,
    featureList,
    modalCamera,
    modalUser,
    modalCompany,
    modalProject,
    modalPermission,
    modalRole,
    modalSubscriptionPackage,
    modalFeature,
    Pagination,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user", "tabs"]),
    ...mapGetters(["activeTab", "availableTabs"]),
  },
  methods: {
    ...mapActions(["logout"]),
    ...mapMutations(["SET_ACTIVE_TAB"]),
  },
  mounted() {},
};
</script>

<style scoped>
.button.logout {
  margin-top: -0.5rem;
}
</style>
