import { SET_SELECTED_PERMISSION } from "@/store/mutations.js";
export const permissions = {
  getFormData: (state) => {
    let formData = new FormData();
    formData.append("title", state.selectedPermission.title);
    formData.append("description", state.selectedPermission.description);
    formData.append("allow", state.selectedPermission.allow);
    formData.append("deny", state.selectedPermission.deny);
    formData.append("isActive", state.selectedPermission.isActive);
    formData.append("id", state.selectedPermission.id);
    formData.append("isDeleted", state.selectedPermission.isDeleted);
    return formData;
  },
  reset: (commit) => {
    commit(SET_SELECTED_PERMISSION, {
      id: null,
      title: "",
      description: "",
      allow: "",
      deny: "",
      isActive: false,
      isDeleted: false,
    });
  },
  saveCallBack: (response, state, dispatch) => {
    state.selectedPermission.id = response.data.id;
    dispatch("fetchList");
  },
  validate: (state) => {
    return (
      state.selectedPermission.title?.length > 2 &&
      (state.selectedPermission.allow || state.selectedPermission.deny) &&
      state.selectedPermission.isActive !== "" &&
      state.selectedPermission.isDeleted !== ""
    );
  },
  getFetchParams: (state) => {
    let params = {};

    if (state.filters.showDeleted) {
      params.company = state.filters.showDeleted;
    }
    return params;
  },
};
