<template>
  <div class="content">
    <p>The following users have access to this company:</p>
    <ul>
      <li v-for="user in selectedCompany.sharedUser" :key="user.id">
        {{ user.fullName }}
      </li>
    </ul>
    <p>This company has following roles:</p>
    <ul>
      <li v-for="role in selectedCompany.sharedRole" :key="role.id">
        {{ role.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["selectedCompany"]),
  },
};
</script>

<style></style>
