<template>
  <div class="users-table">
    <button-group />
    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody v-if="users.length > 0">
        <tr v-for="(user, i) in users" :key="i" @click="edit(user)">
          <td>{{ user.id }}</td>
          <td>{{ user.fullName }}</td>
          <td>{{ user.email }}</td>
          <td>
            <div v-if="user.roles" class="tags">
              <span
                class="tag is-small is-info"
                v-for="role in user.roles"
                :key="role.id"
              >
                {{ role.title }}
              </span>
            </div>
          </td>
          <td>{{ user.isActive | status }}</td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoadingSync">
        <tr>
          <td colspan="6">Loading users...</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">No users found</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ButtonGroup from "@/components/ButtonGroup.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    "button-group": ButtonGroup,
  },
  computed: {
    ...mapState(["users", "isLoadingSync"]),
  },
  filters: {
    status: function (value) {
      return value === "1" ? "Yes" : "No";
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },

  methods: {
    ...mapActions(["fetchList"]),
    ...mapMutations(["SET_SELECTED_USER", "SET_MODAL_STATE"]),
    edit(user) {
      this.SET_SELECTED_USER(user);
      this.SET_MODAL_STATE(true);
    },
  },

  mounted() {
    this.fetchList();
  },
};
</script>

<style></style>
