<template>
  <div>
    <nav :class="['panel', 'is-' + panelTheme]">
      <p class="panel-heading is-size-6">{{ headerText }}</p>
      <div class="panel-block">
        <p
          :class="['control', 'has-icons-left', searching ? 'is-loading' : '']"
        >
          <input
            class="input"
            type="text"
            placeholder="Search"
            v-model="search"
            @keyup="searchItems"
          />
          <span class="icon is-left">
            <i class="fas fa-search" aria-hidden="true"></i>
          </span>
        </p>
      </div>
      <div class="panel-block" v-if="search.length === 0 && items.length === 0">
        <p>{{ messageTextIfNoItems }}</p>
      </div>
      <div class="panel-block" v-if="items.length != 0">
        <p class="control">
          <button
            :class="['button', 'is-outlined', 'is-' + panelTheme]"
            @click="addAllItems"
          >
            <span>{{ actionButtonText }}</span>
          </button>
        </p>
      </div>
      <div class="panel-body">
        <a
          class="panel-block"
          v-for="item in items"
          :key="item.id"
          :data-perm="item.id"
          @dblclick="selectItem(item)"
        >
          <span class="panel-icon">
            <i class="fas fa-book" aria-hidden="true"></i>
          </span>
          {{ item[displayField] }}
        </a>
      </div>
      <div class="panel-block" v-if="pagination">
        <nav
          class="pagination is-small"
          role="navigation"
          aria-label="pagination"
        >
          <span class="mr-5">Page {{ currentPage }} of {{ totalPages }}</span>
          <a
            class="pagination-previous"
            :disabled="currentPage === 1"
            @click="movePage(-1)"
            >Previous</a
          >
          <a
            class="pagination-next"
            :disabled="currentPage === totalPages"
            @click="movePage(1)"
            >Next</a
          >
        </nav>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      search: "",
      currentPage: 1,
      searchInterval: null,
    };
  },
  props: {
    searching: {
      type: Boolean,
      default: false,
    },
    displayField: {
      type: String,
      default: "title",
    },
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    headerText: {
      type: String,
      default: function () {
        return "Items";
      },
    },
    actionButtonText: {
      type: String,
      default: function () {
        return "Add All Items";
      },
    },
    panelTheme: {
      type: String,
      default: function () {
        return "primary";
      },
    },
    messageTextIfNoItems: {
      type: String,
      default: function () {
        return "No items available.";
      },
    },
    pagination: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    totalPages: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    totalRecords: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  methods: {
    selectItem(item) {
      this.$emit("select-item", item);
    },
    addAllItems() {
      this.$emit("action-button-handler");
    },
    searchItems() {
      if (this.searchInterval) {
        clearTimeout(this.searchInterval);
      }
      this.searchInterval = setTimeout(() => {
        // this.searching = true;
        this.$emit("search-items", this.search);
      }, 500);
    },
    movePage(direction) {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage--;
      } else if (direction === 1 && this.currentPage < this.totalPages) {
        this.currentPage++;
      } else {
        return;
      }

      this.$emit("get-page", this.currentPage, this.search);
    },
  },
  computed: {
    // searchItems() {
    //   return this.items.filter((item) => {
    //     return item[this.displayField]
    //       .toLowerCase()
    //       .includes(this.search.toLowerCase());
    //   });
    // },
  },
};
</script>

<style></style>
