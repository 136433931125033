<template>
  <div class="columns">
    <div class="column">
      <Panel
        header-text="Available Features"
        :items="availableFeatures"
        :totalPages="totalPages"
        :totalRecords="totalRecords"
        action-button-text="Add all features"
        panel-theme="link"
        message-text-if-no-items="No features available"
        @action-button-handler="addAllFeatures"
        @select-item="addFeature"
        @search-items="getAvailableFeatures"
        @get-page="getPage"
        :pagination="true"
      />
    </div>
    <div class="column">
      <Panel
        header-text="Selected Features"
        :items="selectedFeatures"
        :totalPages="totalPagesForSharedFeatures"
        :totalRecords="selectedFeatures.length"
        action-button-text="Remove all features"
        panel-theme="danger"
        message-text-if-no-items="No features added"
        @action-button-handler="removeAllFeatures"
        @search-items="searchSelectedFeatures"
        @select-item="removeFeature"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Panel from "@/components/Panel.vue";
export default {
  data: () => ({
    search: "",
    availableFeatures: [],
    page: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 5,
  }),
  components: {
    Panel,
  },
  methods: {
    getPage(page, search) {
      this.page = page;
      this.getAvailableFeatures(search);
    },
    getAvailableFeatures(search) {
      this.$http
        .get("features/", {
          params: {
            op: "list_available_features_for_package",
            pid: this.selectedPackage.id,
            page: this.page,
            perPage: this.perPage,
            search: search,
          },
        })
        .then((response) => {
          this.availableFeatures = response.data.records;
          this.totalPages = response.data.totalPages;
          this.totalRecords = response.data.totalRecords;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchSelectedFeatures(search) {
      this.search = search;
    },
    removeAllFeatures() {
      this.selectedFeatures = [];
    },
    addAllFeatures() {
      this.selectedFeatures = [...this.allFeatures];
    },
    addFeature(feat) {
      let formData = new FormData();
      formData.append("feature", feat.id);
      formData.append("id", this.selectedPackage.id);
      formData.append("op", "attach_feature");

      this.$http
        .post("packages/", formData)
        .then(() => {
          if (this.selectedPackage.sharedFeature) {
            this.selectedPackage.sharedFeature.push(feat);
          } else {
            this.$set(this.selectedPackage, "sharedFeature", [feat]);
          }
          this.getAvailableFeatures();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeFeature(feat) {
      let formData = new FormData();
      formData.append("feature", feat.id);
      formData.append("id", this.selectedPackage.id);
      formData.append("op", "detach_feature");

      this.$http
        .post("packages/", formData)
        .then(() => {
          console.log("Removed feature", feat.id);
          this.selectedPackage.sharedFeature?.splice(
            this.selectedPackage.sharedFeature.findIndex(
              (p) => p.id === feat.id
            ),
            1
          );
          this.getAvailableFeatures();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState(["selectedPackage"]),
    selectedFeatures() {
      return this.selectedPackage.sharedFeature.filter((feat) =>
        feat.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    totalPagesForSharedFeatures() {
      return this.selectedFeatures.length > 0
        ? Math.ceil(this.selectedFeatures.length / this.perPage)
        : 0;
    },
  },
  mounted() {
    this.getAvailableFeatures();
  },
};
</script>

<style>
select {
  display: block;
  width: 100%;
}
.panel-body {
  overflow-y: scroll;
  max-height: 300px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.panel-body::-webkit-scrollbar {
  display: none;
}
</style>
