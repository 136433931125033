<template>
  <div>
    <div class="field">
      <label class="label">ID</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="selectedProject.id"
          disabled
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Name</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedProject.name" />
      </div>
    </div>
    <div class="field">
      <label class="label">Company</label>
      <div class="control">
        <div class="select">
          <select v-model="selectedProject.company_id">
            <option
              v-for="company in companies"
              :value="company.id"
              :key="company.id"
            >
              {{ company.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      companies: [],
    };
  },
  computed: {
    ...mapState(["selectedProject"]),
  },
  mounted() {
    this.$http
      .get("companies/", {
        params: { op: "list_companies", page: 1, perPage: 9999 },
      })
      .then((response) => {
        this.companies = response.data.records;
      });
  },
};
</script>
