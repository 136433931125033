<template>
  <div class="hero is-dark is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <figure class="image p-5">
              <img src="../assets/images/natilon.svg" />
            </figure>
            <form class="box" action="">
              <div class="field">
                <p class="control has-icons-left">
                  <input
                    :class="['input', userInputClass]"
                    type="email"
                    v-model="username"
                    placeholder="E-Posta"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                </p>
                <p class="help is-danger" v-if="userInputClass">
                  Lütfen kullanıcı adınızı girin.
                </p>
              </div>
              <div class="field">
                <p class="control has-icons-left">
                  <input
                    :class="['input', passwordInputClass]"
                    type="password"
                    v-model="password"
                    placeholder="Parola"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-lock"></i>
                  </span>
                </p>
                <p class="help is-danger" v-if="passwordInputClass">
                  Lütfen parolanızı girin.
                </p>
              </div>
              <div class="field">
                <label class="checkbox" for="remember-me">
                  <input type="checkbox" id="remember-me" />
                  Beni Hatırla
                </label>
              </div>
              <div class="level">
                <p class="control level-left">
                  <button
                    class="button is-success"
                    :disabled="inProgress"
                    @click="login_handler"
                  >
                    Oturum Aç
                  </button>
                </p>
              </div>
              <p class="help is-danger" v-if="failed">
                Oturum açma bilgileriniz hatalı ve yanlış. Parolayı tekrar
                girin.
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import router from "@/router/";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      username: process.env.VUE_APP_DEMO_USERNAME || "",
      password: process.env.VUE_APP_DEMO_PASSWORD || "",
      userInputClass: "",
      passwordInputClass: "",
      failed: false,
      inProgress: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  name: "Login",
  methods: {
    ...mapActions(["login", "logout"]),
    async login_handler(e) {
      e.preventDefault();
      this.inProgress = true;
      if (!this.username) {
        this.userInputClass = "is-danger";
        setTimeout(() => {
          this.userInputClass = "";
          this.inProgress = false;
        }, 3000);
        return;
      }

      if (!this.password) {
        this.passwordInputClass = "is-danger";
        setTimeout(() => {
          this.passwordInputClass = "";
          this.inProgress = false;
        }, 3000);
        return;
      }

      var formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);

      this.login(formData)
        .then(() => {})
        .catch((err) => {
          if (err === "No projects or cameras") {
            alert(
              "Proje veya kamera yetkiniz yok. Lütfen proje yöneticiniz ile görüşün"
            );
            router.push("/");
          }
          this.failed = true;
          setTimeout(() => {
            this.failed = false;
            this.inProgress = false;
          }, 3000);
        });
    },
  },
};
</script>
<style scoped>
.hero.is-dark {
  background-color: #000068;
}
</style>
