<template>
  <div class="cameras-table">
    <button-group />

    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Project</th>
          <th>Company</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody v-if="cameras.length > 0">
        <tr v-for="(cam, i) in cameras" :key="i" @click="edit(cam)">
          <td>{{ cam.id }}</td>
          <td>{{ cam.name }}</td>
          <td>{{ cam.project_name }}</td>
          <td>{{ cam.company_name }}</td>
          <td>
            <button
              class="button is-small is-danger"
              @click="deleteCompany(company.id)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoadingSync">
        <tr>
          <td colspan="5">Loading cameras...</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="5">No cameras found</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ButtonGroup from "@/components/ButtonGroup.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    "button-group": ButtonGroup,
  },
  computed: {
    ...mapState(["cameras", "isLoadingSync"]),
  },

  methods: {
    ...mapActions(["fetchList"]),
    ...mapMutations(["SET_SELECTED_CAMERA", "SET_MODAL_STATE"]),
    edit(camera) {
      this.SET_SELECTED_CAMERA(camera);
      this.SET_MODAL_STATE(true);
    },
  },
  mounted() {
    this.fetchList();
  },
};
</script>

<style></style>
