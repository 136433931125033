<template>
  <div class="columns">
    <div class="column">
      <Panel
        header-text="Available Projects"
        :items="availableProjects"
        :totalPages="totalPages"
        :totalRecords="totalRecords"
        action-button-text="Add All Projects"
        panel-theme="link"
        message-text-if-no-items="No Projects Available"
        @action-button-handler="addAllProjects"
        @select-item="addProject"
        @search-items="getAvailableProjects"
        @get-page="getPage"
        displayField="longName"
        :pagination="true"
      />
    </div>
    <div class="column">
      <Panel
        header-text="Selected Projects"
        :items="selectedProjects"
        :totalPages="totalPagesForSharedProjects"
        action-button-text="Remove All Projects"
        panel-theme="danger"
        message-text-if-no-items="No projects given."
        @action-button-handler="removeAllProjects"
        displayField="longName"
        @search-items="searchSelectedProjects"
        @select-item="removeProject"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Panel from "@/components/Panel.vue";
export default {
  data: () => ({
    search: "",
    // selectedProjects: [],
    availableProjects: [],
    page: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 5,
  }),
  components: {
    Panel,
  },
  methods: {
    getPage(page, search) {
      this.page = page;
      this.getAvailableProjects(search);
    },
    getAvailableProjects(search) {
      this.$http
        .get("projects/", {
          params: {
            op: "list_available_projects_for_user",
            search: search,
            uid: this.selectedUser.id,
            page: this.page,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.availableProjects = response.data.records;
          this.totalPages = response.data.totalPages;
          this.totalRecords = response.data.totalRecords;
        });
    },
    searchSelectedProjects(search) {
      this.search = search;
    },
    addAllProjects() {
      this.selectedProjects = [...this.availableProjects];
    },
    removeAllProjects() {
      this.selectedProjects = [];
    },
    addProject(project) {
      let formData = new FormData();
      formData.append("project", project.id);
      formData.append("id", this.selectedUser.id);
      formData.append("op", "attach_project");

      this.$http
        .post("users/", formData)
        .then((response) => {
          console.log(response);
          if (this.selectedUser.projects) {
            this.selectedUser.projects.push(project);
          } else {
            this.$set(this.selectedUser, "projects", [project]);
          }
          this.getAvailableProjects();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeProject(project) {
      let formData = new FormData();
      formData.append("project", project.id);
      formData.append("id", this.selectedUser.id);
      formData.append("op", "detach_project");

      this.$http
        .post("users/", formData)
        .then((response) => {
          console.log(response);
          this.selectedUser.projects?.splice(
            this.selectedUser.projects.findIndex((r) => r.id === project.id),
            1
          );
          this.getAvailableProjects();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState(["selectedUser"]),
    selectedProjects() {
      return this.selectedUser.projects.filter((project) => {
        return project.longName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    totalPagesForSharedProjects() {
      return Math.ceil(this.selectedUser.projects.length / this.perPage);
    },
  },
  mounted() {
    this.getAvailableProjects();
  },
};
</script>
