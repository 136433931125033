<template>
  <div>
    <div class="field">
      <label class="label">ID</label>
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="selectedCompany.id"
          disabled
        />
      </div>
    </div>
    <div class="field">
      <label class="label">Name</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedCompany.name" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["selectedCompany"]),
  },
};
</script>

<style></style>
