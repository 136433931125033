<template>
  <div class="content">
    <p>This permission is being used in the following roles:</p>
    <ul>
      <li v-for="role in selectedPermission.sharedRole" :key="role.id">
        {{ role.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["selectedPermission"]),
  },
};
</script>

<style></style>
