import Vue from "vue";
import axios from "axios";
import router from "@/router/";
import store from "@/store";

const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const devInstance = createInstance(API_ENDPOINT);

devInstance.interceptors.request.use(
  (config) => {
    switch (config.method) {
      case "post":
        if (config.data?.has("op")) {
          console.log(
            config.data.get("op"),
            store.state.user.permissions.includes(config.data.get("op"))
          );
        }
        break;
      case "get":
        console.log(config);
        console.log(
          "has access to",
          config.url,
          store.state.user.permissions.includes(config.params.op),
          "(",
          config.params.op,
          ")"
        );
        if (!store.state.user.permissions.includes(config.params.op)) {
          return Promise.reject({
            status: 403,
            message: "You don't have permission to access this feature",
          });
        }
        break;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
devInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    console.log(err);
    if (
      router.history.current.name !== "Login" &&
      err.response.status === 403
    ) {
      switch (err.response.data.code) {
        case "E_BOOKMARK_NO_PERMISSION":
          alert("Bu işareti kaldırmak için yetkiniz yok.");
          break;
        case "E_PERMISSION_FUNCTION":
          alert("Bu işlemi yapmak için yeterli yetkiniz yok.");
          break;
        case "NO_SELF_ROLE_CHANGE":
          alert("Kendi rolünüzü değiştiremezsiniz.");
          break;
        default:
          alert("Bu sayfaya erişim yetkiniz yok.");
          router.push("/");
          break;
      }
    }
    return Promise.reject(err);
  }
);

function createInstance(baseURL) {
  return axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export default {
  install() {
    Vue.prototype.$http = devInstance;
  },
};
