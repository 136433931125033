<template>
  <div class="content">
    <p>This permission is being used in the following roles:</p>
    <ul>
      <li v-for="pack in selectedFeature.sharedPackage" :key="pack.id">
        {{ pack.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["selectedFeature"]),
  },
};
</script>

<style></style>
