import { SET_SELECTED_PROJECT } from "@/store/mutations.js";
export const projects = {
  getFormData: (state) => {
    let formData = new FormData();
    formData.append("name", state.selectedProject.name);
    formData.append("id", state.selectedProject.id);
    formData.append("company_id", state.selectedProject.company_id);
    formData.append(
      "op",
      state.selectedProject.id ? "update_project" : "create_project"
    );
    return formData;
  },
  saveCallBack: (response, state, dispatch) => {
    state.selectedProject.id = response.data.id;
    dispatch("fetchList");
  },
  validate: (state) => {
    return (
      state.selectedProject.name?.length > 2 && state.selectedProject.company_id
    );
  },
  reset: (commit) => {
    commit(SET_SELECTED_PROJECT, {
      id: null,
      name: "",
      company_id: "",
    });
  },
  getFetchParams: (state) => {
    let params = {};

    if (state.filters.company) {
      params.company = state.filters.company;
    }

    return params;
  },
};
