<template>
  <div class="columns">
    <div class="column">
      <Panel
        header-text="Available Roles"
        :items="availableRoles"
        :totalPages="totalPages"
        action-button-text="Add All Roles"
        panel-theme="link"
        message-text-if-no-items="No Roles Available"
        @action-button-handler="addAllRoles"
        @select-item="addRole"
        @search-items="getAvailableRoles"
        @get-page="getPage"
        :pagination="true"
      />
    </div>
    <div class="column">
      <Panel
        header-text="Selected Roles"
        :items="selectedRoles"
        :totalPages="totalPagesForSharedRoles"
        action-button-text="Remove All Roles"
        panel-theme="danger"
        message-text-if-no-items="No roles given."
        @action-button-handler="removeAllRoles"
        @search-items="searchSelectedRoles"
        @select-item="removeRole"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Panel from "@/components/Panel.vue";
export default {
  data: () => ({
    search: "",
    roles: [],
    availableRoles: [],
    page: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 5,
  }),
  components: {
    Panel,
  },
  methods: {
    getPage(page, search) {
      this.page = page;
      this.getAvailableRoles(search);
    },
    getAvailableRoles(search) {
      this.$http
        .get("roles/", {
          params: {
            op: "list_available_roles_for_company",
            cid: this.selectedCompany.id,
            page: this.page,
            perPage: this.perPage,
            search: search,
          },
        })
        .then((response) => {
          this.availableRoles = response.data.records;
          this.totalPages = response.data.totalPages;
          this.totalRecords = response.data.totalRecords;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchSelectedRoles(search) {
      this.search = search;
    },
    removeAllRoles() {
      this.selectedRoles = [];
    },
    addAllRoles() {
      this.selectedRoles = [...this.allRoles];
    },
    addRole(role) {
      let formData = new FormData();
      formData.append("roleId", role.id);
      formData.append("companyId", this.selectedCompany.id);
      formData.append("op", "attach_role");

      this.$http
        .post("companies/", formData)
        .then((response) => {
          console.log(response);

          if (this.selectedCompany.roles) {
            this.selectedCompany.roles.push(role);
          } else {
            this.$set(this.selectedCompany, "roles", [role]);
          }
          this.getAvailableRoles();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeRole(role) {
      let formData = new FormData();
      formData.append("roleId", role.id);
      formData.append("companyId", this.selectedCompany.id);
      formData.append("op", "detach_role");

      this.$http
        .post("companies/", formData)
        .then((response) => {
          console.log(response);
          this.selectedCompany.roles?.splice(
            this.selectedCompany.roles?.findIndex((r) => r.id === role.id),
            1
          );
          this.getAvailableRoles();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState(["selectedUser", "selectedCompany"]),
    selectedRoles() {
      return this.selectedCompany.roles.filter((role) =>
        role.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    totalPagesForSharedRoles() {
      return Math.ceil(this.selectedCompany.roles.length / this.perPage);
    },
  },
  mounted() {
    this.getAvailableRoles();
  },
};
</script>

<style>
select {
  display: block;
  width: 100%;
}
.panel-body {
  overflow-y: scroll;
  max-height: 300px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.panel-body::-webkit-scrollbar {
  display: none;
}
</style>
