import { SET_SELECTED_COMPANY } from "@/store/mutations.js";
export const companies = {
  fields: {
    id: null,
    name: "",
    awsBucket: "",
    awsRegion: "",
    awsIotUser: "",
    awsIotUserPolicy: "",
    awsUploadKey: "",
    awsUploadSecret: "",
  },
  getFormData: (state) => {
    let formData = new FormData();
    formData.append("name", state.selectedCompany.name);
    formData.append("id", state.selectedCompany.id);
    formData.append("awsBucket", state.selectedCompany.awsBucket);
    formData.append("awsRegion", state.selectedCompany.awsRegion);
    formData.append("awsIotUser", state.selectedCompany.awsIotUser);
    formData.append("awsIotUserPolicy", state.selectedCompany.awsIotUserPolicy);
    formData.append("awsUploadKey", state.selectedCompany.uploadKey);
    formData.append("awsUploadSecret", state.selectedCompany.uploadSecret);
    formData.append(
      "op",
      state.selectedCompany.id ? "update_company" : "create_company"
    );
    return formData;
  },
  saveCallBack: (response, state, dispatch) => {
    state.selectedCompany.id = response.data.id;
    dispatch("fetchList");
  },
  validate: (state) => {
    return state.selectedCompany.name?.length > 2;
  },
  reset: (commit) => {
    commit(SET_SELECTED_COMPANY, {
      id: null,
      name: "",
      awsBucket: "",
      awsRegion: "",
      awsIotUser: "",
      awsIotUserPolicy: "",
      awsUploadKey: "",
      awsUploadSecret: "",
      roles: [],
      companies: [],
    });
  },
};
