<template>
  <div class="companies-table">
    <button-group />
    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>AWS Bucket</th>
          <th>Oluşturma</th>
          <th>Güncelleme</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody v-if="companies.length > 0">
        <tr v-for="(company, i) in companies" :key="i" @click="edit(company)">
          <td>{{ company.id }}</td>
          <td>{{ company.name }}</td>
          <td>{{ company.awsBucket }}</td>
          <td>{{ company.createdAt }}</td>
          <td>{{ company.updatedAt }}</td>
          <td>
            <button
              class="button is-small is-danger"
              @click="deleteCompany(company.id)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoadingSync">
        <tr>
          <td colspan="4">Loading companies...</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="4">No companies found.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ButtonGroup from "@/components/ButtonGroup.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    "button-group": ButtonGroup,
  },
  computed: {
    ...mapState(["companies", "isLoadingSync"]),
  },
  methods: {
    ...mapActions(["fetchList"]),
    ...mapMutations(["SET_SELECTED_COMPANY", "SET_MODAL_STATE"]),
    edit(company) {
      console.log(company);
      this.SET_SELECTED_COMPANY(company);
      this.SET_MODAL_STATE(true);
    },
  },
  mounted() {
    this.fetchList();
  },
};
</script>

<style></style>
