<template>
  <div>
    <div class="field">
      <label class="label">ID</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedRole.id" disabled />
      </div>
    </div>
    <div class="field">
      <label class="label">Title</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedRole.title" />
      </div>
    </div>
    <div class="field">
      <label class="label">Description</label>
      <div class="control">
        <input class="input" type="text" v-model="selectedRole.description" />
      </div>
    </div>
    <div class="field">
      <label class="label">Status</label>
      <div class="control">
        <div class="select">
          <select v-model="selectedRole.isActive">
            <option value="0">Passive</option>
            <option value="1">Active</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field">
      <label class="label has-text-danger">Deleted</label>
      <div class="control">
        <div class="select is-danger">
          <select v-model="selectedRole.isDeleted">
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["selectedRole"]),
  },
};
</script>

<style></style>
