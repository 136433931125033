import router from "@/router/";
import LogRocket from "logrocket";

import {
  SET_USERS,
  SET_IS_AUTHENTICATED,
  SET_USER_FULLNAME,
  SET_USER_PERMISSONS,
  SET_COMPANIES,
  SET_CAMERAS,
  SET_PROJECTS,
  SET_PERMISSIONS,
  SET_ROLES,
  SET_PACKAGES,
  SET_FEATURES,
  SET_SELECTED_CAMERA,
  SET_SELECTED_COMPANY,
  SET_SELECTED_PROJECT,
  SET_SELECTED_USER,
  SET_SELECTED_PERMISSION,
  SET_SELECTED_ROLE,
  SET_SELECTED_PACKAGE,
  SET_SELECTED_FEATURE,
  SET_MODAL_STATE,
  SET_IS_LOADING_SYNC,
  SET_IS_SAVING,
  // SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  SET_TOTAL_RECORDS,
} from "@/store/mutations.js";

import { users } from "@/store/tables/users";
import { companies } from "@/store/tables/companies";
import { cameras } from "@/store/tables/cameras";
import { projects } from "@/store/tables/projects";
import { permissions } from "@/store/tables/permissions";
import { roles } from "@/store/tables/roles";
import { packages } from "@/store/tables/packages";
import { features } from "@/store/tables/features";

const tables = {
  users,
  companies,
  cameras,
  projects,
  permissions,
  roles,
  packages,
  features,
};

function resetState({ commit }) {
  commit(SET_IS_AUTHENTICATED, false);
  commit(SET_USER_FULLNAME, "");
  commit(SET_USERS, []);
  commit(SET_COMPANIES, []);
  commit(SET_CAMERAS, []);
  commit(SET_PROJECTS, []);
  commit(SET_PERMISSIONS, []);
  commit(SET_ROLES, []);
  commit(SET_PACKAGES, []);
  commit(SET_FEATURES, []);
  commit(SET_MODAL_STATE, false);
  commit(SET_SELECTED_CAMERA, null);
  commit(SET_SELECTED_COMPANY, tables.companies.fields);
  commit(SET_SELECTED_PROJECT, null);
  commit(SET_SELECTED_USER, null);
  commit(SET_SELECTED_PERMISSION, null);
  commit(SET_SELECTED_ROLE, null);
  commit(SET_IS_LOADING_SYNC, false);
  commit(SET_SELECTED_PACKAGE, null);
  commit(SET_SELECTED_FEATURE, null);
}
export const actions = {
  fetchList({ commit, state, getters }) {
    let table = getters.activeTab;
    let tableName = table.table;
    let mutation = "SET_" + tableName.toUpperCase();
    commit(SET_IS_LOADING_SYNC, true);

    commit(mutation, []);
    let params = {
      search: state.search ? state.search : "",
      page: table.currentPage,
      perPage: table.perPage,
      op: "list_" + tableName,
    };

    if (typeof tables[tableName].getFetchParams === "function") {
      params = {
        ...tables[tableName].getFetchParams(state),
        ...params,
      };
    }

    console.log(params);

    this._vm.$http
      .get(tableName + "/", {
        params,
      })
      .then((response) => {
        commit(mutation, response.data.records);
        commit(SET_TOTAL_RECORDS, {
          tabIndex: getters.activeTabIndex,
          totalRecords: response.data.totalRecords,
          totalPages: response.data.totalPages,
        });
        commit(SET_IS_LOADING_SYNC, false);
      });
  },
  reset({ commit, getters }) {
    let table = tables[getters.activeTab.table];
    table.reset(commit);
  },
  save({ commit, getters, state, dispatch }) {
    let table = tables[getters.activeTab.table];

    if (!table.validate(state)) {
      this._vm.$toast.open({
        type: "error",
        position: "top",
        message: "Please fill all required fields",
      });
      return;
    }

    commit(SET_IS_SAVING, true);

    this._vm.$http
      .post(`${getters.activeTab.table}/`, table.getFormData(state))
      .then((response) => {
        table.saveCallBack(response, state, dispatch);
        this._vm.$toast.open({
          position: "top",
          message: response.data.message,
        });
      })
      .catch((error) => {
        if (error.response.status === 409) {
          this._vm.$toast.open({
            type: "error",
            position: "top",
            message: "This entity already exists.",
          });
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        commit(SET_IS_SAVING, false);
      });
  },
  login({ commit }, formData) {
    return new Promise((resolve, reject) => {
      this._vm.$http
        .post("login/", formData)
        .then((response) => {
          let data = response.data;
          if (!data.isAuthenticated) {
            resetState({ commit });
            reject("Not authenticated");
          }

          commit(SET_IS_AUTHENTICATED, true);
          commit(SET_USER_FULLNAME, data.full_name);
          commit(SET_USER_PERMISSONS, data.permissions);

          LogRocket.identify(data.id, {
            name: data.full_name,
            email: data.email,
          });

          router.push("/admin/");
          resolve();
        })
        .catch((err) => {
          if (err.response.status) {
            resetState({ commit });
            reject("Not authenticated");
          }
        });
    });
  },
  resetPassword({ commit }, email) {
    commit(SET_IS_AUTHENTICATED, false);
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append("email", email);
      formData.append("op", "RESET_PASSWORD");

      this._vm.$http
        .post("user/", formData)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  logout({ commit }) {
    resetState({ commit });
    router.push("/");
  },
  backToLogin() {
    if (router.currentRoute.path === "/") {
      return;
    }
    router.push("/");
  },
  setPageSize({ commit }, payload) {
    commit(SET_PAGE_SIZE, {
      tabIndex: payload.tabIndex,
      pageSize: payload.pageSize,
    });
  },
};
