import { SET_SELECTED_ROLE } from "@/store/mutations.js";
export const roles = {
  getFormData: (state) => {
    let formData = new FormData();
    formData.append("title", state.selectedRole.title);
    formData.append("description", state.selectedRole.description);
    formData.append("isActive", state.selectedRole.isActive);
    formData.append("id", state.selectedRole.id);
    formData.append("isDeleted", state.selectedRole.isDeleted);
    formData.append(
      "op",
      state.selectedRole.id ? "update_role" : "create_role"
    );
    return formData;
  },
  reset: (commit) => {
    commit(SET_SELECTED_ROLE, {
      id: null,
      title: "",
      description: "",
      isActive: false,
      isDeleted: false,
      permissions: [],
    });
  },
  saveCallBack: (response, state, dispatch) => {
    state.selectedRole.id = response.data.id;
    dispatch("fetchList");
  },
  validate: (state) => {
    return (
      state.selectedRole.title?.length > 2 &&
      state.selectedRole.isActive !== "" &&
      state.selectedRole.isDeleted !== ""
    );
  },
  getFetchParams: (state) => {
    let params = {};

    if (state.filters.showDeleted) {
      params.company = state.filters.showDeleted;
    }
    return params;
  },
};
