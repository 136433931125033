<template>
  <div class="columns">
    <div class="column">
      <Panel
        header-text="Available Companies"
        :items="availableCompanies"
        :totalPages="totalPages"
        :totalRecords="totalRecords"
        action-button-text="Add All Companies"
        panel-theme="link"
        message-text-if-no-items="No Companies Available"
        @action-button-handler="addAllCompanies"
        @select-item="addCompany"
        @search-items="getAvailableCompanies"
        @get-page="getPage"
        displayField="name"
        :pagination="true"
      />
    </div>
    <div class="column">
      <Panel
        header-text="Selected Companies"
        :items="selectedCompanies"
        :totalPages="totalPagesForSharedCompanies"
        action-button-text="Remove All Companies"
        panel-theme="danger"
        message-text-if-no-items="No companies given."
        @action-button-handler="removeAllCompanies"
        @search-items="searchSelectedCompanies"
        displayField="name"
        @select-item="removeCompany"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Panel from "@/components/Panel.vue";
export default {
  data: () => ({
    search: "",
    // selectedCompanies: [],
    availableCompanies: [],
    page: 1,
    totalPages: 0,
    totalRecords: 0,
    perPage: 5,
  }),
  components: {
    Panel,
  },
  methods: {
    getPage(page, search) {
      this.page = page;
      this.getAvailableCompanies(search);
    },
    getAvailableCompanies(search) {
      this.$http
        .get("companies/", {
          params: {
            op: "list_available_companies_for_user",
            search: search,
            uid: this.selectedUser.id,
            page: this.page,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          console.log(response);
          this.availableCompanies = response.data.records;
          this.totalPages = response.data.totalPages;
          this.totalRecords = response.data.totalRecords;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchSelectedCompanies(search) {
      this.search = search;
    },
    removeAllCompanies() {
      this.selectedCompanies = [];
    },
    addAllCompanies() {
      this.selectedcompanies = [...this.availableCompanies];
    },
    addCompany(company) {
      let formData = new FormData();
      formData.append("company", company.id);
      formData.append("id", this.selectedUser.id);
      formData.append("op", "attach_company");

      this.$http
        .post("users/", formData)
        .then((response) => {
          console.log(response);
          if (this.selectedUser.companies) {
            this.selectedUser.companies.push(company);
          } else {
            this.$set(this.selectedUser, "companies", [company]);
          }
          this.getAvailableCompanies();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeCompany(company) {
      let formData = new FormData();
      formData.append("company", company.id);
      formData.append("id", this.selectedUser.id);
      formData.append("op", "detach_company");

      this.$http
        .post("users/", formData)
        .then((response) => {
          console.log(response);
          this.selectedUser.companies?.splice(
            this.selectedUser.companies.findIndex((r) => r.id === company.id),
            1
          );
          this.getAvailableCompanies();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState(["selectedUser"]),
    // availableRoles() {
    //   return this.availableRoles.filter((role) => {
    //     return !this.selectedUser.sharedRole?.find((r) => r.id === role.id);
    //   });
    // },
    selectedCompanies() {
      return this.selectedUser.companies.filter((company) =>
        company.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
    totalPagesForSharedCompanies() {
      return Math.ceil(this.selectedUser.companies?.length / this.perPage);
    },
  },
  mounted() {
    this.getAvailableCompanies();
  },
};
</script>

<style>
select {
  display: block;
  width: 100%;
}
.panel-body {
  overflow-y: scroll;
  max-height: 300px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.panel-body::-webkit-scrollbar {
  display: none;
}
</style>
