<template>
  <div class="feature-table">
    <button-group />
    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Description</th>
          <th>Value</th>
          <th>Active</th>
          <th v-if="filters.showDeleted">Deleted</th>
          <th>Created</th>
          <th>Updated</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="features.length > 0">
        <tr v-for="(feat, i) in features" :key="i" @click="edit(feat)">
          <td>{{ feat.id }}</td>
          <td>{{ feat.name }}</td>
          <td>{{ feat.description }}</td>
          <td>{{ feat.value }}</td>
          <td>{{ feat.isActive }}</td>
          <td v-if="filters.showDeleted">{{ feat.isDeleted }}</td>
          <td>{{ feat.createdAt }}</td>
          <td>{{ feat.updatedAt }}</td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoadingSync">
        <tr>
          <td colspan="6">Loading features...</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">No features found</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ButtonGroup from "@/components/ButtonGroup.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    "button-group": ButtonGroup,
  },
  computed: {
    ...mapState(["features", "isLoadingSync", "filters"]),
  },
  methods: {
    ...mapActions(["fetchList"]),
    ...mapMutations(["SET_SELECTED_FEATURE", "SET_MODAL_STATE"]),
    edit(pack) {
      this.SET_SELECTED_FEATURE(pack);
      this.SET_MODAL_STATE(true);
    },
  },

  mounted() {
    this.fetchList();
  },
};
</script>

<style></style>
