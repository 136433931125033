<template>
  <div class="users-table">
    <button-group />
    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Title</th>
          <th>Description</th>
          <th>Allow</th>
          <th>Deny</th>
          <th>Active</th>
          <th v-if="filters.showDeleted">Deleted</th>
          <th>Created</th>
          <th>Updated</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="permissions.length > 0">
        <tr v-for="(perm, i) in permissions" :key="i" @click="edit(perm)">
          <td>{{ perm.id }}</td>
          <td>{{ perm.title }}</td>
          <td>{{ perm.description }}</td>
          <td>{{ perm.allow }}</td>
          <td>{{ perm.deny }}</td>
          <td>{{ perm.isActive }}</td>
          <td v-if="filters.showDeleted">{{ perm.isDeleted }}</td>
          <td>{{ perm.createdAt }}</td>
          <td>{{ perm.updatedAt }}</td>
        </tr>
      </tbody>
      <tbody v-else-if="isLoadingSync">
        <tr>
          <td colspan="6">Loading permissions...</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="6">No permission found</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ButtonGroup from "@/components/ButtonGroup.vue";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  components: {
    "button-group": ButtonGroup,
  },
  computed: {
    ...mapState(["permissions", "isLoadingSync", "filters"]),
  },
  methods: {
    ...mapActions(["fetchList"]),
    ...mapMutations(["SET_SELECTED_PERMISSION", "SET_MODAL_STATE"]),
    edit(permission) {
      this.SET_SELECTED_PERMISSION(permission);
      this.SET_MODAL_STATE(true);
    },
  },

  mounted() {
    this.fetchList();
  },
};
</script>

<style></style>
